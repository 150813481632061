<template>
  <var-style-provider :style-vars="{ '--dialog-width': '420px'}">
    <var-dialog
        v-model:show="show"
        confirm-button-text="Add"
        cancel-button-text="Cancel"
        @before-close="onBeforeClose">
      <template #title>
        Add Project Permission
      </template>

      <var-skeleton :rows="7" :loading="loading">
        <var-form ref="form">
          <var-select
              placeholder="Project"
              :rules="[v => !!v || 'You must select a project']"
              v-model="formData.project_id"
              class="mb-3"
          >
            <var-option v-for="project in projects" :label="project.name" :value="project.id"/>
          </var-select>
          <var-select
              placeholder="Permission Level"
              :rules="[v => v.length >= 1 || 'You must select a level']"
              v-model="formData.permission"
              class="mb-3"
          >
            <var-option label="Read Only" value="read"/>
            <var-option label="Read and Write" value="write"/>
          </var-select>
        </var-form>
      </var-skeleton>
    </var-dialog>
  </var-style-provider>
</template>

<script>

import {reactive, ref} from "vue";
import request from "../request";

export default {
  name: "AddProjectPermissionModal",
  props: {
    member: {
      type: Object,
      required: true
    }
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal && !this.servers) {
        this.getProjects()
      }
    }
  },
  setup() {
    const formData = reactive({
      project_id: null,
      permission: null
    })

    const form = ref(null)

    return {
      form,
      formData
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      projects: null,
      onBeforeClose: (action, done) => {
        if (action === 'confirm') {
          this.form.validate().then(validated => {
            if (!validated) {
              return
            }

            this.loading = true

            request.put(process.env.VUE_APP_BASE_URL + '/members/' + this.member.id + '/projects/' + this.formData.project_id, {type: this.formData.permission})
                .then(data => {
                  this.loading = false
                  done()
                })
                .catch(error => {
                  this.loading = false
                  alert(error.response.data.message)
                })
          })
        } else {
          done()
        }
      },
    }
  },
  methods: {
    getProjects() {
      this.loading = true;
      request.get(process.env.VUE_APP_BASE_URL + `/members/${this.member.id}/available_projects`)
          .then(data => {
            this.projects = data.data

            if (!this.projects.length) {
              alert('no projects left')
            }

            this.loading = false
          })
          .catch(error => {
            this.loading = false
            console.log(error)
          })
    }
  }
}
</script>

<style scoped>

</style>