<template>
	<var-skeleton :rows="7" :loading="loading">
		<breadcrumbs :current-item-title="`${member.first_name} ${member.last_name}`" :items="breadcrumbItems"/>

		<var-card
			title="Member Details"
			class="mb-4"
		>
			<template #extra>
				<var-form ref="form" :disabled="loadingSave">
					<var-input
						placeholder="First Name"
						:rules="[v => !!v || 'The first name cannot be empty']"
						v-model="formData.first_name"
						class="mb-4"
					/>
					<var-input
						placeholder="Last Name"
						:rules="[v => !!v || 'The last name cannot be empty']"
						v-model="formData.last_name"
						class="mb-4"
					/>
					<var-input
						placeholder="Email"
						v-model="formData.email"
						disabled
						class="mb-4"
					/>
					<var-row justify="space-between">
						<var-col v-if="formData.status !== 'Invited'" :span="8">
							<h6>Active</h6>
							<var-switch
								@click="switchStatus"
								v-model="status"
								:loading="loadingStatus"
							/>
						</var-col>
						<var-col :span="8" class="text-end">
              <var-space v-if="member?.status === 'Invited'">
                <var-button
                    type="primary"
                    :loading="loadingResend"
                    @click="resend"
                >
                  Resend Invitation
                </var-button>
                <var-button
                    type="primary"
                    :loading="loadingSave"
                    @click="save"
                >
                  Save
                </var-button>
              </var-space>
              <var-button
                  v-else
                  type="primary"
                  :loading="loadingSave"
                  @click="save"
              >
                Save
              </var-button>
						</var-col>
					</var-row>

				</var-form>
			</template>
		</var-card>

		<var-card
			title="Permissions"
			class="mb-4"
		>
			<template #extra>
				<var-space direction="column" size="30">
					<div class="project-permissions">
						<var-divider description="Project-Level Permissions"/>
						<div v-if="member.project_permissions.length > 0">
							<project-permission-cell
								v-for="projectPermission in member.project_permissions"
								:permission="projectPermission.project"
								:name="projectPermission.type"
								@onEditClick="onEditClick(projectPermission.id, projectPermission.type)"
								@onDeleteClick="onDeleteClick(projectPermission.id)"
							/>

							<var-col :span="8" class="text-end">
								<var-button type="primary" @click="$refs.addProjectPermissionModal.show = true">Add
								</var-button>
							</var-col>
						</div>

						<div v-else class="permissions-placeholder">
							<h4>This member doesn't have any project-level permissions granted yet!</h4>
							<var-button type="primary" @click="$refs.addProjectPermissionModal.show = true">Add one
							</var-button>
						</div>
					</div>

					<div class="organization-permissions">
						<var-divider description="Organization-Level Permissions"/>

						<h6>Create Projects</h6>
						<var-switch
							@click="updateCreateProjectPermission"
							v-model="member.can_create_projects"
							:loading="loadingProjectPermission"
						/>

						<h6>Connect GitHub Accounts</h6>
						<var-switch
							@click="updateConnectAccountsPermission"
							v-model="member.can_connect_accounts"
							:loading="loadingAccountPermission"
						/>
					</div>
				</var-space>
			</template>
		</var-card>
	</var-skeleton>

	<add-project-permission-modal :member="member" ref="addProjectPermissionModal"/>
	<edit-project-permission-modal :member-id="member?.id" ref="editProjectPermissionModal"/>
	<delete-project-permission-modal :member-id="member?.id" ref="deleteProjectPermissionModal"/>
</template>

<script>
import {reactive, ref} from "vue";
import request from "../request";
import AddProjectPermissionModal from "../components/AddProjectPermissionModal.vue";
import ProjectPermissionCell from "../components/ProjectPermissionCell.vue";
import EditProjectPermissionModal from "../components/EditProjectPermissionModal.vue";
import DeleteProjectPermissionModal from "../components/DeleteProjectPermissionModal.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import {Snackbar} from "@varlet/ui";

export default {
	name: "MemberDetails",
	components: {
		Breadcrumbs,
		DeleteProjectPermissionModal,
		EditProjectPermissionModal, ProjectPermissionCell, AddProjectPermissionModal
	},
	setup() {
		const formData = reactive({
			id: null,
			first_name: null,
			last_name: null,
			email: null,
			status: null
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	created() {
		this.getMemberData()
	},
	data() {
		return {
			loading: true,
			loadingSave: false,
			loadingStatus: false,
			loadingResend: false,
			loadingProjectPermission: false,
			loadingAccountPermission: false,
			breadcrumbItems: [
				{
					title: 'Members',
					to: 'Members',
					params: {}
				}
			],
			status: false,
			member: null
		}
	},
	methods: {
		getMemberData() {
			this.loading = true

			request.get(process.env.VUE_APP_BASE_URL + '/members/' + this.$route.params.id)
				.then(data => {
					this.loading = false
					this.member = data.data
					this.formData = {...this.member}
					this.status = this.formData.status === 'Active'
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		switchStatus() {
			this.loadingStatus = true

			request.put(process.env.VUE_APP_BASE_URL + '/members/' + this.$route.params.id, {
				status: this.status ? 'inactive' : 'active'
			})
				.then(data => {
					this.loadingStatus = false
					this.formData.status = data.status
					this.status = this.formData.status === 'Active'
				})
				.catch(error => {
					this.loadingStatus = false
				})
		},
		updateCreateProjectPermission() {
			this.loadingProjectPermission = true

			request.put(process.env.VUE_APP_BASE_URL + '/members/' + this.$route.params.id, {
				can_create_projects: !this.member.can_create_projects
			})
				.then(data => {
					this.loadingProjectPermission = false
					this.member = {...data.data}
				})
				.catch(error => {
					this.loadingProjectPermission = false
				})
		},
		updateConnectAccountsPermission() {
			this.loadingAccountPermission = true

			request.put(process.env.VUE_APP_BASE_URL + '/members/' + this.$route.params.id, {
				can_connect_accounts: !this.member.can_connect_accounts
			})
				.then(data => {
					this.loadingAccountPermission = false
					this.member = {...data.data}
				})
				.catch(error => {
					this.loadingAccountPermission = false
				})
		},
		onEditClick(permissionId, permission) {
			this.$refs.editProjectPermissionModal.permissionId = permissionId
			this.$refs.editProjectPermissionModal.permission = permission
			this.$refs.editProjectPermissionModal.show = true
		},
		onDeleteClick(permissionId) {
			this.$refs.deleteProjectPermissionModal.permissionId = permissionId
			this.$refs.deleteProjectPermissionModal.show = true
		},
    resend() {
      this.loadingResend = true

      request.post(process.env.VUE_APP_BASE_URL + `/members/${this.member.id}/resend_invitation`)
          .then(response => {
            this.loadingResend = false
            Snackbar['success']("Successfully resent the invitation.")
          })
          .catch(error => {
            this.loadingResend = false
            Snackbar['error']("Failed to resend the invitation, please try again later.")
          })
    },
		save() {
			this.loading = true

			request.put(process.env.VUE_APP_BASE_URL + '/members/' + this.$route.params.id, {
				first_name: this.formData.first_name,
				last_name: this.formData.last_name,
			})
				.then(response => {
					this.loading = false
					const data = response.data

					this.member = {...data}
					this.formData = {...this.member}
				})
				.catch(error => {
					this.loading = false
				})
		}
	}
}
</script>

<style scoped>

.permissions-placeholder {
	height: 120px;
	border: 1px dashed gray;
	text-align: center;
	padding-top: 1.5rem;
}

</style>