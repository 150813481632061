<template>
  <var-style-provider :style-vars="{ '--dialog-width': '420px'}">
    <var-dialog
        v-model:show="show"
        confirm-button-text="Add"
        cancel-button-text="Cancel"
        @before-close="onBeforeClose">
      <template #title>
        Edit Project Permission
      </template>

      <var-skeleton :rows="7" :loading="loading">
        <var-form>
          <var-select
              placeholder="Permission Level"
              :rules="[v => v.length >= 1 || 'You must select a level']"
              v-model="permission"
              class="mb-3"
          >
            <var-option label="Read Only" value="read"/>
            <var-option label="Read and Write" value="write"/>
          </var-select>
        </var-form>
      </var-skeleton>
    </var-dialog>
  </var-style-provider>
</template>

<script>

import {reactive, ref} from "vue";
import request from "../request";

export default {
  name: "EditProjectPermissionModal",
  props: {
    memberId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      permission: null,
      permissionId: null,
      onBeforeClose: (action, done) => {
        if (action === 'confirm') {
          this.loading = true

          request.patch(process.env.VUE_APP_BASE_URL + '/members/' + this.memberId + '/permissions/' + this.permissionId, {type: this.permission})
              .then(data => {
                this.loading = false
                done()
              })
              .catch(error => {
                this.loading = false
                alert(error.response.data.message)
              })
        } else {
          done()
        }
      },
    }
  }
}
</script>

<style scoped>

</style>